import * as React from "react";
const SvgComponent = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={28}
    height={25}
    fill="none"
    {...props}
  >
    <path
      fill="#9A9A9A"
      d="M26.038 17.564H8.592l.876-1.785 14.555-.026c.492 0 .913-.352 1.001-.838L27.04 3.633a1.018 1.018 0 0 0-.999-1.198L7.525 2.373l-.158-.744a1.04 1.04 0 0 0-1.013-.82H1.827a1.034 1.034 0 0 0 0 2.068h3.689l.691 3.287 1.702 8.241-2.191 3.577a1.024 1.024 0 0 0-.088 1.079c.176.348.53.568.923.568h1.84a3.008 3.008 0 0 0 2.402 4.813 3.008 3.008 0 0 0 2.402-4.813h4.72a3.008 3.008 0 0 0 2.402 4.813 3.008 3.008 0 0 0 2.403-4.813h3.319c.568 0 1.034-.463 1.034-1.034a1.038 1.038 0 0 0-1.037-1.031ZM7.956 4.412l16.87.056-1.653 9.252-13.251.023-1.966-9.33Zm2.839 17.95a.928.928 0 0 1-.926-.925c0-.51.416-.926.926-.926a.928.928 0 0 1 .655 1.58.926.926 0 0 1-.655.271Zm9.524 0a.928.928 0 0 1-.925-.925c0-.51.416-.926.925-.926a.928.928 0 0 1 .655 1.58.926.926 0 0 1-.655.271Z"
    />
  </svg>
);
export default SvgComponent;
